<template>
    <ckeditor ref="editor" :editor="editor" v-model="value" :config="editorConfig" @ready="onReady"></ckeditor>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import FullBuildClassicEditor from 'custom-ckeditor5';
import 'custom-ckeditor5/build/translations/tr';
import UploadAdapter from "@/core/plugins/upload-adapter";

export default {
    name: "index",
    props: ['model', 'enterToBr', 'basicEditor'], //props: ['id', 'config', 'value', 'language', 'height', 'placeholder'],
    components: {
        ckeditor: CKEditor.component
    },
    data() {
        return {
            editorInstance: null,
            editor: FullBuildClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        'fontBackgroundColor',
                        'imageInsert',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'highlight',
                        'removeFormat',
                        '|',
                        'alignment',
                        '|',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'indent',
                        'outdent',
                        '|',
                        'todoList',
                        'link',
                        'blockQuote',
                        'imageUpload',
                        'insertTable',
                        'mediaEmbed',
                        'htmlEmbed',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        'math',
                        '|',
                        'sourceEditing'
                    ],
                    shouldNotGroupWhenFull: false
                },
                language: 'tr',
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        '|',
                        'imageStyle:block',
                        'imageStyle:side',
                        '|',
                        'linkImage'
                    ]
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells'
                    ]
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true
                        }
                    ],
                },
                mediaEmbed: {
                    previewsInData: true,
                    extraProviders: [
                        {
                            // hint: this is just for previews. Get actual HTML codes by making API calls from your CMS
                            name: 'Support Mp4',

                            // Match all URLs or just the ones you need:
                            url: /^(.*)\.mp4$/,

                            html: match => {
                                return ('<video controls="" name="media"><source src="'+match[0]+'" type="video/mp4"></video>');
                            }
                        },
                        {
                            // hint: this is just for previews. Get actual HTML codes by making API calls from your CMS
                            name: 'Iframe',

                            // Match all URLs or just the ones you need:
                            url: /^iframe:(.*)$/,

                            html: match => {
                                try {
                                    let url = new URL(match[1]);
                                    let foundAttrs = [];

                                    ['width', 'height'].forEach((attr) => {
                                        if(url.searchParams.get(attr)){
                                            foundAttrs.push(this.sprintf('%s="%s"', [attr, url.searchParams.get(attr)]));
                                        }
                                    });

                                    return (this.sprintf('<iframe src="%s" %s></iframe>', [url, foundAttrs.join(' ')]));
                                } catch (e) {
                                    return ('Iframe URL Error');
                                }
                            }
                        }
                    ]
                },
                math: {
                    engine: 'katex', // or katex or function. E.g. (equation, element, display) => { ... }
                    lazyLoad: undefined, // async () => { ... }, called once before rendering first equation if engine doesn't exist. After resolving promise, plugin renders equations.
                    outputType: 'span', // or span
                    forceOutputType: false, // forces output to use outputType
                    enablePreview: true, // Enable preview view
                    previewClassName: [], // Class names to add to previews
                    popupClassName: [], // Class names to add to math popup balloon
                },
                fontSize: {
                    options: [
                        6,
                        8,
                        10,
                        'tiny',
                        12,
                        'small',
                        14,
                        'default',
                        16,
                        18,
                        20,
                        'big',
                        24,
                        28,
                        'huge',
                        32,
                        36,
                        48
                    ]
                },
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                    ]
                },
                licenseKey: ''
            }
        }
    },
    created(){
        if(this.basicEditor) {
            this.editorConfig.toolbar.items = [
                'bold',
                'italic',
                'fontColor',
                '|',
                'undo',
                'redo',
            ]
        }
    },
    computed:{
        value: {
            get() {
                return this.model != null ? this.model : undefined
            },
            set(val) {
                this.$emit('updateModel', val)
            }
        },
    },
    methods: {
        onReady(editor)  {
            this.editorInstance = editor;

            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );

            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                return new UploadAdapter(loader);
            };

            if(this.enterToBr){
                editor.editing.view.document.on('enter', ( evt, data ) => {
                    editor.execute('shiftEnter');
                    data.preventDefault();
                    evt.stop();
                }, {priority: 'high' });
            }
        },
    }
}
</script>

<style>
.ck .ck-splitbutton {
    display: flex;
}
.ck .ck-content {
    max-height: 800px;
}
.ck .ck-source-editing-area {
    max-height: 800px;
}
.ck .ck-source-editing-area textarea{
    max-height: 800px;
    overflow-y: auto;
}

/* width */
.ck .ck-source-editing-area textarea::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.ck .ck-source-editing-area textarea::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.ck .ck-source-editing-area textarea:hover::-webkit-scrollbar-thumb {
    background: #e8edf1;
}
.ck .ck-source-editing-area textarea::-webkit-scrollbar-thumb {
    background: #eff2f5;
}

.el-form-item.is-error .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners{
    border-color: #f56c6c;
}

.ck.ck-math-form {
    padding: 0.6em !important;
}

.ck.ck-math-form:focus {
    outline: none !important;
}

[dir=ltr] .ck.ck-math-form>:not(:first-child),[dir=rtl] .ck.ck-math-form>:not(:last-child) {
    margin-left: 0.6em !important;
}

@media screen and (max-width:600px) {
    .ck.ck-math-form {
        padding: 0 !important;
    }

    .ck.ck-math-form .ck-labeled-input {
        margin: 0.6em 0.6em 0 !important;
    }

    .ck.ck-math-form .ck-labeled-input .ck-input-text {
        min-width: 0 !important;;
        width: 100% !important;
    }

    .ck.ck-math-form .ck-label,.ck.ck-math-form .ck-math-view .ck-button,.ck.ck-math-form .ck-math-view .ck.ck-math-preview {
        margin: 0.6em 0.6em 0 !important;
    }

    .ck.ck-math-form>.ck-button {
        padding: 0.6em !important;
        margin-top: 0.6em !important;
        border-radius: 0 !important;
        border: 0 !important;
        border-top: 1px solid #c4c4c4 !important;
    }

    [dir=ltr] .ck.ck-math-form>.ck-button {
        margin-left: 0 !important;
    }

    [dir=ltr] .ck.ck-math-form>.ck-button:first-of-type {
        border-right: 1px solid #c4c4c4 !important;
    }

    [dir=rtl] .ck.ck-math-form>.ck-button {
        margin-left: 0 !important;
    }

    [dir=rtl] .ck.ck-math-form>.ck-button:last-of-type {
        border-right: 1px solid #c4c4c4 !important;
    }
}

</style>